<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in tabs"
                        :key="index"
                        class="py-3 text-center cursor-pointer"
                        :class="
                            item.value === choseTab
                                ? 'bg-red-500 bg-opacity-20 rounded-lg'
                                : ''
                        "
                        @click="changeTab(item.value)"
                    >
                        {{ item.label }}
                    </li>
                    <li v-if="datas.length < 11" class="my-5">
                        <button
                            class="w-full py-3 text-white bg-red-500 rounded-lg"
                            @click="createOrUpdatePage"
                        >
                            <i class="fas fa-plus"></i>
                            新增即刻快閃範本單
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9">
            <div
                v-loading="loading"
                class="overflow-x-auto bg-white rounded-lg shadow-lg"
            >
                <div class="overflow-x-auto">
                    <table>
                        <TableHead
                            ref="tableHeadRefDom"
                            :customClass="'p-3 border-b border-gray-100'"
                            :labels="tableLabels"
                            @changeSort="changeSort"
                        />
                        <tbody>
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.provider_required }}人</td>
                                <td>{{ item.hourly_pay | formatCurrency }}</td>
                                <td>
                                    {{
                                        choseTab === "real"
                                            ? item.created_at
                                            : item.updated_at | formatDateTime
                                    }}
                                </td>
                                <td
                                    v-if="route.params.type === 'vorder'"
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.demand_id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                                <td
                                    v-else
                                    v-permission="['delete']"
                                    class="text-red-500 cursor-pointer"
                                    @click="
                                        showDialog = true;
                                        deleteOrderData.name = item.name;
                                        deleteOrderData.id = item.demand_id;
                                    "
                                >
                                    <i class="far fa-trash-alt"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog
            :showDialog="showDialog"
            :customClass="'top-1/4 z-[70]'"
            :customBlackBgClass="'z-[60]'"
            @onCloseDialog="closeDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>確認{{ deleteOrderData.name }}刪除嗎？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog"
                    >
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteOrder(deleteOrderData.id)"
                    >
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// table Head
import TableHead from "@/components/table/TableHead.vue";
/**
 * isEmpty: 判斷是 empty 方法
 * createHashId: 新增亂數 id 方法
 */
import { isEmpty, createHashId } from "@/service/anyService";
import moment from "moment";
// 導入自定義彈窗組件
import MyDialog from "@/components/Dialog.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // table head dom
  const tableHeadRefDom = ref(null);

  // table head 標題
  const tableLabels = computed(() => {
    return [{
      label: "#"
    }, {
      label: "活動名稱"
    }, {
      label: "活動需求人數"
    }, {
      label: "每小時活動出席費"
    }, {
      label: route.params.type === "real" ? "開單時間" : "最後編輯時間"
    }, {
      label: route.params.type === "real" ? "刪除" : "編輯"
    }];
  });

  // 搜尋表單 dom
  const loading = ref(false);

  // 顯示刪除彈窗判斷
  const showDialog = ref(false);
  function closeDialog() {
    showDialog.value = false;
  }

  // 刪除訂單名稱
  const deleteOrderData = ref({});

  // 列表
  const datas = ref([]);

  // 選擇 tab
  const choseTab = ref(route.params.type);

  // 審核狀態列表
  const tabs = ref([{
    label: "會員開即刻快閃",
    value: "real"
  }, {
    label: "即刻快閃範本單",
    value: "vorder"
  }]);

  /**
   * 選擇 tab 事件
   * @param { type Number(數字) } val 驗證狀態
   */
  function changeTab(val) {
    if (choseTab.value !== val) {
      router.push({
        name: "rightnow_activity_marquee",
        params: {
          type: val
        }
      });
    }
    datas.value = [];
    choseTab.value = val;
    getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetRightNowActivityMarqueeListApi();
      datas.value = route.params.type === "real" ? data.by_consumer : data.by_admin;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goToEdit(id) {
    router.push({
      name: "rightnow_activity_marquee_detail",
      params: {
        type: "update",
        id
      }
    });
  }

  // 點擊重置按鈕事件
  async function resetData() {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    tableHeadRefDom.value.reset();
    paginationData.value = {
      limit: 10,
      total: 0
    };
    await getList();
  }

  // 更換排序
  const changeSort = async val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    await getList();
  };

  /**
   * 導向新增或更新頁
   */
  async function createOrUpdatePage() {
    const id = await createHashId("spo", moment().valueOf(), 5);
    router.push({
      name: "rightnow_activity_marquee_detail",
      params: {
        type: "create",
        id
      }
    });
  }

  /**
   * 刪除評論
   * @param { type String or Number(字串或數字) } id 訂單 id
   */
  async function deleteOrder(id) {
    loading.value = true;
    try {
      await proxy.$api.DeleteRightNowActivityMarqueeDetailApi(id);
      await getList();
      proxy.$message({
        type: "success",
        message: "刪除成功"
      });
      deleteOrderData.value = {};
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "刪除失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  onMounted(async () => {
    deleteOrderData.value = {};
    await getList();
  });
  onActivated(async () => {
    deleteOrderData.value = {};
    await getList();
  });
  return {
    route,
    tableHeadRefDom,
    tableLabels,
    loading,
    showDialog,
    closeDialog,
    deleteOrderData,
    datas,
    choseTab,
    tabs,
    changeTab,
    goToEdit,
    changeSort,
    createOrUpdatePage,
    deleteOrder
  };
};
__sfc_main.components = Object.assign({
  TableHead,
  MyDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
table {
    @apply w-full;
}
th {
    @apply p-3 text-gray-400 font-normal min-w-[100px];
}
td {
    @apply p-3 border-b border-gray-200 min-w-[100px];
}
</style>
